<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->

    <div class="kt-header__topbar-item kt-header__topbar-item--user"  v-if="this.showUserpanel">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle" data-toggle="dropdown">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome">Hi,</span>
          <span class="kt-header__topbar-username">{{username}}</span>
        
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
          >{{userintital}}</span>
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-sm dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      username: "",
      userintital:"",
      showUserpanel:true
    };
  },
  components: {
    KTDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  mounted() {
    if (JwtService.getToken()) {
      const firstName = JwtService.getToken().split("~")[2];
      this.username = firstName;
      this.userintital = firstName.charAt(0);
    }
    else{
            this.showUserpanel=false;
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
