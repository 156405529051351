<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-bind:menu="menu" :key="i"></KTMenuItem>
    </template>

    <img :src="getImgUrl(id)" />
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem,
  },
  data() {
    return {
      id: 1,
    };
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("@/assets/media/logos/", false, /\.png$/);
      return images("./Companylogo_" + pet + ".png");
    },
  },
  computed: {
    menuItems: () => {
      return menuConfig.header.items;
    },
  },
  created() {
    if (JwtService.getFacilityToken() != "") {
      this.id = JwtService.getFacilityToken().split('~')[0];
      //console.log("moible menu " + this.id);
    }
  },
};
</script>
