<template>
  <div>
    <!--begin: Head -->
     
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <div class="kt-notification__custom kt-space-between text-center">
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTDropdownUser",
  data() {
    return {
      logoutpage: "",
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: this.logoutpage }));
    },
  },
  mounted() {
    if (JwtService.getToken()) {
      const isAdmin = JwtService.getToken().split('~')[3];
      if (isAdmin === "true") 
      {
        this.logoutpage = "adminlogin";
      }
      else this.logoutpage = "login";
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
  },
};
</script>
